import './FaqBody.css';
import {useState} from "react";
import axios from 'axios';

const FaqBody = () => {

    const [show, setShow] = useState(false);

    const showHandler = (event) => {
        const {nextSibling, parentNode} = event.target.parentNode;
        if (show) {
            nextSibling.style.display = 'none';
            parentNode.className = 'faq-item not-active-item';
            setShow(false);
        } else {
            nextSibling.style.display = 'flex';
            parentNode.className = 'faq-item active-item';
            setShow(true);
        }
    }
    const [displayStyle, setDisplayStyle] = useState(
    [   
        {
            yes: {'display': "inlineBlock"},
            no: {'display': "inlineBlock"},
            clicked: false

        },
        {
            yes: {'display': "inlineBlock"},
            no: {'display': "inlineBlock"},
            clicked: false

        },
        {
            yes: {'display': "inlineBlock"},
            no: {'display': "inlineBlock"},
            clicked: false

        },
        {
            yes: {'display': "inlineBlock"},
            no: {'display': "inlineBlock"},
            clicked: false

        },
        {
            yes: {'display': "inlineBlock"},
            no: {'display': "inlineBlock"},
            clicked: false

        },
        {
            yes: {'display': "inlineBlock"},
            no: {'display': "inlineBlock"},
            clicked: false

        },
        
    ])
    const [faqState, setFaqState] = useState(
        {
            name: "",
            useful: false
        }
    )
    const setCurrentFaqStyle = (number, answer) =>{
        const newDisplayStyle = [...displayStyle];
        if(answer==="yes"){
            newDisplayStyle[number].no = {"display": "none"}
        }else if(answer==="no"){
            newDisplayStyle[number].yes = {"display": "none"}
        }
        newDisplayStyle[number].clicked=true;
        setDisplayStyle(newDisplayStyle);
    }
    const sendFeedback = async (name, useful, number, answer) =>{
        setCurrentFaqStyle(number, answer)
        setFaqState(
            name, useful
        )
        if(!displayStyle[number].clicked){
            await axios.post('https://api.asista.kz/faq', faqState);
        }
     }

    return (
        <div className='FAQ-body'>
            <div className='faq-item not-active-item'>
                <div className='icons-title_block'>
                    <div className='block-covering' onClick={showHandler}/>
                    <div className='close-add-icon'/>
                    <h1 className='faq-item_title'>ПК не включается</h1>
                </div>
                <div className='faq-hidden-answer'>
                    <ul className='list-advises'>
                        <li>Проверить включена ли УПС (должна гореть лампочка синяя или зеленая постоянно).</li>
                        <li>Проверить кнопку на блоке питания ПК (расположена на задней задней части ПК выглядит как
                            тумблер, должна быть нажата часть с полоской «–«).
                        </li>
                        <li>Проверить подключен ли кабель питания ПК и монитора в удлинитель.</li>
                        <li>Проверить включен ли удлинитель.</li>
                    </ul>
                    <div className='faq_question' > 
                        <span className='faq_question-text'>Было полезно? </span>
                        <button className='faq_question-btn' style={displayStyle[0].yes} onClick={()=>{sendFeedback("pc_not_booting", true, 0 , "yes")}}>да</button>
                        <button className='faq_question-btn' style={displayStyle[0].no} onClick={()=>{sendFeedback("pc_not_booting", false, 0, "no")}}>нет</button>
                    </div>
                </div>
            </div>
            <div className='faq-item not-active-item'>
                <div className='icons-title_block'>
                    <div className='block-covering' onClick={showHandler}/>
                    <div className='close-add-icon'/>
                    <h1 className='faq-item_title'>Ноутбук не включается</h1>
                </div>
                <div className='faq-hidden-answer'>
                    <ul className='list-advises'>
                        <li>Проверить подключено ли зарядное устройство (отключить и подключить повторно).</li>
                        <li>Проверить подключено ли зарядное устройство в удлинитель.</li>
                        <li>Проверить включен ли удлинитель.</li>
                    </ul>
                    <div className='faq_question' > 
                        <span className='faq_question-text'>Было полезно? </span>
                        <button className='faq_question-btn' style={displayStyle[1].yes} onClick={()=>{sendFeedback("laptop_not_booting", true,1 , "yes")}}>да</button>
                        <button className='faq_question-btn' style={displayStyle[1].no}  onClick={()=>{sendFeedback("laptop_not_booting", false,1, "no")}}>нет</button>
                    </div>
                </div>
            </div>
            <div className='faq-item not-active-item'>
                <div className='icons-title_block'>
                    <div className='block-covering' onClick={showHandler}/>
                    <div className='close-add-icon'/>
                    <h1 className='faq-item_title'>Мышка и клавиатура</h1>
                </div>
                <div className='faq-hidden-answer'>
                    <p>Проводная</p>
                    <ul className='list-advises'>
                        <li>Проверить подключен ли кабель USB мышки в ПК или ноутбук (отключить и подключить
                            повторно).
                        </li>
                    </ul>
                    <p>Беспроводная</p>
                    <ul className='list-advises'>
                        <li>Проверить включатель на нижней части клавиатуры и мышки (исполнен как тумблер с обозначения
                            вкл/выкл или цветами зеленый вкл, красный выкл).
                        </li>
                        <li>Заменить аккумуляторы (батарейки).</li>
                        <li>На ПК или ноутбуке в USB разъёмах найти адаптер (выглядит как очень маленькая флешка USB),
                            вытащить и подключить обратно.
                        </li>
                    </ul>
                    <div className='faq_question' > 
                        <span className='faq_question-text'>Было полезно? </span>
                        <button className='faq_question-btn' style={displayStyle[2].yes} onClick={()=>{sendFeedback("mouse_and_keyboard", true,2 , "yes")}}>да</button>
                        <button className='faq_question-btn' style={displayStyle[2].no}  onClick={()=>{sendFeedback("mouse_and_keyboard", false,2, "no")}}>нет</button>
                    </div>
                </div>
            </div>
            <div className='faq-item not-active-item'>
                <div className='icons-title_block'>
                    <div className='block-covering' onClick={showHandler}/>
                    <div className='close-add-icon'/>
                    <h1 className='faq-item_title'>Программы по умолчанию</h1>
                </div>
                <div className='faq-hidden-answer'>
                    <ul className='list-advises'>
                        <li>В меню Пуск выберите Параметры {'>'} приложения {'>'} приложения по умолчанию. Выберите параметр по
                            умолчанию в левой части, затем выберите нужные программы по умолчанию нажимая на иконки и
                            выбирая программы из всплывающего списка.
                        </li>
                    </ul>
                    <div className='faq_question'> 
                        <span className='faq_question-text'>Было полезно? </span>
                        <button className='faq_question-btn' style={displayStyle[3].yes} onClick={()=>{sendFeedback("default_programms", true,3 , "yes")}}>да</button>
                        <button className='faq_question-btn' style={displayStyle[3].no}  onClick={()=>{sendFeedback("default_programms", false,3, "no")}}>нет</button>
                    </div>
                </div>
            </div>
            <div className='faq-item_long'>
                <div className='faq-item not-active-item'>
                    <div className='icons-title_block'>
                        <div className='block-covering' onClick={showHandler}/>
                        <div className='close-add-icon'/>
                        <h1 className='faq-item_title'>Не печатает принтер</h1>
                    </div>
                    <div className='faq-hidden-answer'>
                        <p>Очередь печати:</p>
                        <ul className='list-advises'>
                            <li>Открыть очередь печати, значок принтера он должен показываться в нижнем меню, справа.
                                Там же еще расположено: Время, язык, интернет. Есть скрытые значки, которые открываются
                                нажатием на стрелочку. После чего откроется квадрат со всеми программами запущенными.
                            </li>
                            <li>Дважды кликаем по значку принтера, раскрываем меню «Принтер» (Printer) и выбираем
                                «Очистить очередь печати» (Cancel All Documents).
                            </li>
                        </ul>
                        <p>Подключен не подключен:</p>
                        <ul className='list-advises'>
                            <li>Если не печатает принтер, то первое, что можно сделать это перезагрузить его вместе с
                                компьютером. Затем, проверить шнур питания и интерфейсный — USB:
                            </li>
                            <li>отключить шнур питания и подключить его заново;</li>
                            <li>переподключить кабель USB.</li>
                            <li>Если шнур-USB подключен в разъем на передней панели системного блока, то подключил бы
                                его к задней панели. Если у вас ноутбук, или шнур USB и так подключен к задней панели,
                                то можно подключить его в другой разъем.
                            </li>
                            <li>Нужно проверить, установлен ли принтер по умолчанию, не стоит ли печать на паузе и не
                                включен ли автономный режим.
                            </li>
                            <li>Открываем: Пуск. {'>'} Панель управления {'>'} Оборудование и звук {'>'} Устройства и принтеры</li>
                            <li>Дважды кликаем по значку принтера. В открывшемся окошке раскрываем меню
                                «Принтер».Устанавливаем если не стоит галочка:
                            </li>
                            <ul className='list-advises'>
                                <li>Использовать по умолчанию (Set As Default Printer).Снимаем галочки (если такие
                                    установлены):
                                </li>
                                <li>Приостановить печать (Pause Printing);</li>
                                <li>Работать автономно (Use Printer Offline).</li>
                            </ul>
                        </ul>
                        <div className='faq_question'> 
                            <span className='faq_question-text'>Было полезно? </span>
                            <button className='faq_question-btn' style={displayStyle[4].yes} onClick={()=>{sendFeedback("printer_not_printing", true,4 , "yes")}}>да</button>
                            <button className='faq_question-btn' style={displayStyle[4].no}  onClick={()=>{sendFeedback("printer_not_printing", false,4, "no")}}>нет</button>
                        </div>
                    </div>
                </div>
                <div className='faq-item not-active-item'>
                    <div className='icons-title_block'>
                        <div className='block-covering' onClick={showHandler}/>
                        <div className='close-add-icon'/>
                        <h1 className='faq-item_title'>NCAlayer</h1>
                    </div>
                    <div className='faq-hidden-answer'>
                        <ul className='list-advises'>
                            <li>Не запускается NCA Layer (Ошибка при подключении к NCA Layer)
                                Первое что нужно сделать, это проверить запущен ли NCA Layer. Он должен показываться в
                                нижнем меню, справа. Там же еще расположено: Время, язык, интернет. Есть скрытые значки,
                                которые открываются нажатием на стрелочку. После чего откроется квадрат со всеми
                                программами запущенными. В нем должен гореть значок NCA Layer. Если он есть, но он горит
                                с красным крестом, то нужно нажать на него правой кнопкой мыши и в появившемся окне,
                                выбрать закрыть (выход). После по новой запустить программу NCA Layer. Если ее нет на
                                рабочем столе, то поискать программу по название в меню Пуск, которое находится снизу
                                слева.
                            </li>
                            <li>Так же может выходить ошибка модуля (Самое распространенное – Отсутствует модуль КНП)Его
                                можно легко самим установить. Для этого нужно перейти в нижнее меню, справа. Там же еще
                                расположено: Время, язык, интернет. Есть скрытые значки, которые открываются нажатием на
                                стрелочку. После чего откроется квадрат со всеми программами запущенными. В нем должен
                                гореть значок NCA Layer. Нажать на значок правой кнопкой мыши и выбрать – «Установить
                                модули». В появившемся окне, в списке найти нужный модуль и нажать установить его. После
                                программа сама все установит и перезагрузит себя. После нужно перезагрузить нужный вам
                                сайт и зайдет бес проблем.
                            </li>
                            <li>Все запущено, но не открывается NCA Layer для выбора ЭЦП. Нужно сбросить кэш браузера. В
                                каждом браузере, слева от ссылки сайта есть кнопка в виде Замка . Нажав на нее, появится
                                окно где нужно зайти в «Настройка сайтов». После в открывшемся окне найти
                                «Использование» и ниже будут данные записанные в размерах кб или мб, так же количество
                                cookie файлов. Правее будет кнопка – «Удалить данные». Нажимаем на нее и перезагружаем
                                сайт. После пробуем еще раз войти на сайт с ЭЦП.
                            </li>
                        </ul>
                        <p> Перечень порталов, для работы с которыми необходимо использовать последнюю версию NCAlayer:</p>
                        <ul className='list-advises'>
                            <li>Национальный удостоверяющий центр РК (получение или продление ЭЦП);</li>
                            <li> Электронное правительство РК (справки и запросы);</li>
                            <li> Портал Электронные счета-фактуры (ИС ЭСФ);</li>
                            <li> Государственные закупки РК (участие в тендерах);</li>
                            <li> ИС Казначейство клиент;</li>
                            <li> Комитет Статистики РК (отправки и доступ к истории статистических отчетов);</li>
                            <li> Тендерная площадка Самрук-Казына;</li>
                            <li> Судебный кабинет.</li>
                        </ul>
                        <div className='faq_question' > 
                        <span className='faq_question-text'>Было полезно? </span>
                        <button className='faq_question-btn' style={displayStyle[5].yes} onClick={()=>{sendFeedback("ncalayer", true,5 , "yes")}}>да</button>
                        <button className='faq_question-btn' style={displayStyle[5].no}  onClick={()=>{sendFeedback("ncalayer", false,5, "no")}}>нет</button>
                    </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default FaqBody;